<template>
  <div class="documents edit pd-20 bg" ref="container">
    <a-form :form="form" @submit="handleSubmit">
      <a-page-header class="mb-0 pd-0" @back="() => $router.go(-1)">
        <div slot="title">
          <span class="doc-title dotted-phrase"
            >Documento #{{ document.id }} -
            {{ form.getFieldValue(`name`) }}</span
          >
          <a-tooltip placement="top" title="Visualizar documento">
            <a
              class="cblue ml-10 relative"
              @click="view(document.id)"
              style="top: -5px"
            >
              <a-icon type="eye-svg" style="zoom: 0.04; position: relative" />
            </a>
          </a-tooltip>
        </div>

        <template slot="extra">
          <a-form-item class="travel-input-outer" v-if="listDocs.length > 0">
            <a-select
              class="travel-input"
              show-search
              placeholder="Ir para documento "
              style="width: 400px"
              optionFilterProp="txt"
              v-model="selectedDoc"
              @select="goToSelectedDoc"
            >
              <a-select-option
                v-for="(item, index) of listDocs"
                :key="index"
                :value="item.id"
                :txt="item.name"
              >
                {{ item.id }} -

                {{ item.name }} [{{
                  item.status == 1 ? "Ativo" : "Desativado"
                }}]
              </a-select-option>
            </a-select>
          </a-form-item>
        </template>
      </a-page-header>

      <a-row :span="24" :offset="0">
        <a-col v-show="!pageLoaded" class="col-1" :span="18">
          <a-row>
            <a-col :span="21">
              <a-form-item class="fade-label">
                <label
                  :class="this.form.getFieldValue(`title`) ? 'filled' : ''"
                >
                  Título do documento
                </label>
                <a-input
                  placeholder="Título do documento"
                  v-decorator="[
                    `title`,
                    {
                      validateTrigger: ['blur'],
                      rules: [
                        {
                          required: true,
                          message: '',
                        },
                      ],
                    },
                  ]"
                >
                </a-input>
              </a-form-item>
            </a-col>

            <a-col :span="21">
              <a-form-item class="fade-label">
                <label
                  :class="this.form.getFieldValue(`subtitle`) ? 'filled' : ''"
                >
                  Subtítulo do documento
                </label>
                <a-input
                  placeholder="Subtítulo do documento (opcional)"
                  v-decorator="[`subtitle`]"
                >
                </a-input>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col
              class="paragraphs"
              :class="drag ? 'is-dragging' : ''"
              :span="23"
            >
              <draggable
                v-model="document_paragraph"
                group="people"
                @start="startDrag"
                @end="endDrag"
              >
                <a-row
                  v-for="(paragraph, index) in document_paragraph"
                  :key="index"
                  class="paragraph"
                  type="flex"
                  justify="center"
                  :gutter="[20, 0]"
                >
                  <a-col :span="22">
                    <a-form-item>
                      <vue-editor
                        v-model="paragraph.p"
                        placeholder="Digite o texto aqui"
                        :editor-toolbar="customToolbar"
                        @text-change="
                          updateParagraph(index, paragraph.id, paragraph.p)
                        "
                        @blur="
                          updateParagraphExtraCongifs(
                            index,
                            paragraph.id,
                            paragraph
                          )
                        "
                      ></vue-editor>

                      <a-input
                        class="travel-textarea"
                        placeholder="Descrição da política"
                        style="display: none !important"
                        type="textarea"
                        v-decorator="[
                          `document_paragraph_${paragraph.id}`,
                          {
                            rules: [
                              {
                                required: false,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]"
                      >
                      </a-input>
                    </a-form-item>

                    <div
                      class="travel-collapse"
                      v-if="activeCollapse == index ? true : false"
                    >
                      <a-row class="info">
                        <a-col :span="24">
                          CSS para o container do parágrafo.
                        </a-col>
                      </a-row>

                      <a-row
                        class="fields"
                        type="flex"
                        justify="start"
                        :gutter="16"
                      >
                        <a-col flex="100px">
                          <font class="label"> Borda (px) </font>
                        </a-col>
                        <a-col>
                          <a-form-item>
                            <a-input
                              class="travel-input"
                              placeholder="Top"
                              style="width: 90px"
                              v-decorator="[
                                `document_paragraph_${paragraph.id}_border_top`,
                              ]"
                              @blur="
                                updateParagraphExtraCongifs(
                                  index,
                                  paragraph.id,
                                  paragraph
                                )
                              "
                            >
                              <a-icon slot="prefix" type="arrow-up" />
                            </a-input>
                          </a-form-item>
                        </a-col>
                        <a-col>
                          <a-form-item>
                            <a-input
                              class="travel-input"
                              placeholder="Right"
                              style="width: 90px"
                              v-decorator="[
                                `document_paragraph_${paragraph.id}_border_right`,
                              ]"
                              @blur="
                                updateParagraphExtraCongifs(
                                  index,
                                  paragraph.id,
                                  paragraph
                                )
                              "
                            >
                              <a-icon slot="prefix" type="arrow-right" />
                            </a-input>
                          </a-form-item>
                        </a-col>
                        <a-col>
                          <a-form-item>
                            <a-input
                              class="travel-input"
                              placeholder="Bottom"
                              style="width: 90px"
                              v-decorator="[
                                `document_paragraph_${paragraph.id}_border_bottom`,
                              ]"
                              @blur="
                                updateParagraphExtraCongifs(
                                  index,
                                  paragraph.id,
                                  paragraph
                                )
                              "
                            >
                              <a-icon slot="prefix" type="arrow-down" />
                            </a-input>
                          </a-form-item>
                        </a-col>
                        <a-col>
                          <a-form-item>
                            <a-input
                              class="travel-input"
                              placeholder="Left"
                              style="width: 90px"
                              v-decorator="[
                                `document_paragraph_${paragraph.id}_border_left`,
                              ]"
                              @blur="
                                updateParagraphExtraCongifs(
                                  index,
                                  paragraph.id,
                                  paragraph
                                )
                              "
                            >
                              <a-icon slot="prefix" type="arrow-left" />
                            </a-input>
                          </a-form-item>
                        </a-col>

                        <a-col>
                          <a-form-item>
                            <div class="form__input">
                              <VSwatches
                                :swatches="swatches"
                                v-model="paragraph.css.boderColor"
                                show-fallback
                                popover-x="left"
                              ></VSwatches>
                            </div>
                            <a-input
                              class="travel-input"
                              placeholder="Color"
                              style="width: 90px; display: none"
                              v-decorator="[
                                `document_paragraph_${paragraph.id}_border_color`,
                              ]"
                              @blur="
                                updateParagraphExtraCongifs(
                                  index,
                                  paragraph.id,
                                  paragraph
                                )
                              "
                            >
                              <a-icon slot="prefix" type="highlight" />
                            </a-input>
                          </a-form-item>
                        </a-col>
                      </a-row>

                      <a-row
                        class="fields"
                        type="flex"
                        justify="start"
                        :gutter="16"
                      >
                        <a-col flex="100px">
                          <font class="label"> Padding (px) </font>
                        </a-col>
                        <a-col>
                          <a-form-item>
                            <a-input
                              class="travel-input"
                              placeholder="Top"
                              style="width: 90px"
                              v-decorator="[
                                `document_paragraph_${paragraph.id}_padding_top`,
                              ]"
                              @blur="
                                updateParagraphExtraCongifs(
                                  index,
                                  paragraph.id,
                                  paragraph
                                )
                              "
                            >
                              <a-icon slot="prefix" type="arrow-up" />
                            </a-input>
                          </a-form-item>
                        </a-col>
                        <a-col>
                          <a-form-item>
                            <a-input
                              class="travel-input"
                              placeholder="Right"
                              style="width: 90px"
                              v-decorator="[
                                `document_paragraph_${paragraph.id}_padding_right`,
                              ]"
                              @blur="
                                updateParagraphExtraCongifs(
                                  index,
                                  paragraph.id,
                                  paragraph
                                )
                              "
                            >
                              <a-icon slot="prefix" type="arrow-right" />
                            </a-input>
                          </a-form-item>
                        </a-col>
                        <a-col>
                          <a-form-item>
                            <a-input
                              class="travel-input"
                              placeholder="Bottom"
                              style="width: 90px"
                              v-decorator="[
                                `document_paragraph_${paragraph.id}_padding_bottom`,
                              ]"
                              @blur="
                                updateParagraphExtraCongifs(
                                  index,
                                  paragraph.id,
                                  paragraph
                                )
                              "
                            >
                              <a-icon slot="prefix" type="arrow-down" />
                            </a-input>
                          </a-form-item>
                        </a-col>
                        <a-col>
                          <a-form-item>
                            <a-input
                              class="travel-input"
                              placeholder="Left"
                              style="width: 90px"
                              v-decorator="[
                                `document_paragraph_${paragraph.id}_padding_left`,
                              ]"
                              @blur="
                                updateParagraphExtraCongifs(
                                  index,
                                  paragraph.id,
                                  paragraph
                                )
                              "
                            >
                              <a-icon slot="prefix" type="arrow-left" />
                            </a-input>
                          </a-form-item>
                        </a-col>
                      </a-row>

                      <a-row
                        class="fields"
                        type="flex"
                        justify="start"
                        :gutter="16"
                      >
                        <a-col flex="100px">
                          <font class="label"> Margin (px) </font>
                        </a-col>
                        <a-col>
                          <a-form-item>
                            <a-input
                              class="travel-input"
                              placeholder="Top"
                              style="width: 90px"
                              v-decorator="[
                                `document_paragraph_${paragraph.id}_margin_top`,
                              ]"
                              @blur="
                                updateParagraphExtraCongifs(
                                  index,
                                  paragraph.id,
                                  paragraph
                                )
                              "
                            >
                              <a-icon slot="prefix" type="arrow-up" />
                            </a-input>
                          </a-form-item>
                        </a-col>
                        <a-col>
                          <a-form-item>
                            <a-input
                              class="travel-input"
                              placeholder="Right"
                              style="width: 90px"
                              v-decorator="[
                                `document_paragraph_${paragraph.id}_margin_right`,
                              ]"
                              @blur="
                                updateParagraphExtraCongifs(
                                  index,
                                  paragraph.id,
                                  paragraph
                                )
                              "
                            >
                              <a-icon slot="prefix" type="arrow-right" />
                            </a-input>
                          </a-form-item>
                        </a-col>
                        <a-col>
                          <a-form-item>
                            <a-input
                              class="travel-input"
                              placeholder="Bottom"
                              style="width: 90px"
                              v-decorator="[
                                `document_paragraph_${paragraph.id}_margin_bottom`,
                              ]"
                              @blur="
                                updateParagraphExtraCongifs(
                                  index,
                                  paragraph.id,
                                  paragraph
                                )
                              "
                            >
                              <a-icon slot="prefix" type="arrow-down" />
                            </a-input>
                          </a-form-item>
                        </a-col>
                        <a-col>
                          <a-form-item>
                            <a-input
                              class="travel-input"
                              placeholder="Left"
                              style="width: 90px"
                              v-decorator="[
                                `document_paragraph_${paragraph.id}_margin_left`,
                              ]"
                              @blur="
                                updateParagraphExtraCongifs(
                                  index,
                                  paragraph.id,
                                  paragraph
                                )
                              "
                            >
                              <a-icon slot="prefix" type="arrow-left" />
                            </a-input>
                          </a-form-item>
                        </a-col>
                      </a-row>

                      <a-row
                        class="fields"
                        type="flex"
                        justify="start"
                        :gutter="16"
                      >
                        <a-col flex="100px">
                          <font class="label"> Font size (px) </font>
                        </a-col>
                        <a-col>
                          <a-form-item>
                            <a-input
                              class="travel-input"
                              placeholder="Size"
                              style="width: 90px"
                              v-decorator="[
                                `document_paragraph_${paragraph.id}_font_size`,
                              ]"
                              @blur="
                                updateParagraphExtraCongifs(
                                  index,
                                  paragraph.id,
                                  paragraph
                                )
                              "
                            >
                              <a-icon slot="prefix" type="font-size" />
                            </a-input>
                          </a-form-item>
                        </a-col>

                        <a-col flex="100px">
                          <font class="label"> Colunas </font>
                        </a-col>
                        <a-col>
                          <a-form-item>
                            <a-select
                              v-decorator="[
                                `document_paragraph_${paragraph.id}_cols`,
                              ]"
                              @change="
                                updateParagraphExtraCongifs(
                                  index,
                                  paragraph.id,
                                  paragraph
                                )
                              "
                              class="travel-input"
                              default-value="
                                                                   1
                                                                "
                              style="width: 100px"
                            >
                              <a-select-option value="1">
                                1 coluna
                              </a-select-option>
                              <a-select-option value="2">
                                2 colunas
                              </a-select-option>
                            </a-select>
                          </a-form-item>
                        </a-col>

                        <a-col flex="100px">
                          <font class="label"> Nº da página </font>
                        </a-col>
                        <a-col>
                          <a-form-item>
                            <a-select
                              v-decorator="[
                                `document_paragraph_${paragraph.id}_page`,
                              ]"
                              @change="
                                updateParagraphExtraCongifs(
                                  index,
                                  paragraph.id,
                                  paragraph
                                )
                              "
                              class="travel-input"
                              style="width: 120px"
                            >
                              <a-select-option value="1">
                                1ª página
                              </a-select-option>
                              <a-select-option value="2">
                                2ª página
                              </a-select-option>
                              <a-select-option value="3">
                                3ª página
                              </a-select-option>
                              <a-select-option value="4">
                                4ª página
                              </a-select-option>
                              <a-select-option value="5">
                                5ª página
                              </a-select-option>
                              <a-select-option value="6">
                                6ª página
                              </a-select-option>
                              <a-select-option value="7">
                                7ª página
                              </a-select-option>
                              <a-select-option value="8">
                                8ª página
                              </a-select-option>
                              <a-select-option value="9">
                                9ª página
                              </a-select-option>
                              <a-select-option value="10">
                                10ª página
                              </a-select-option>
                            </a-select>
                          </a-form-item>
                        </a-col>
                      </a-row>

                      <a-row
                        class="fields"
                        type="flex"
                        justify="start"
                        :gutter="16"
                      >
                      </a-row>
                    </div>

                    <div style="font-size: 10px; color: #888; padding-top: 5px">
                      <a-icon type="file-text" />
                      <font
                        v-if="paragraph.page != undefined"
                        :class="paragraph.page != undefined ? '' : 'red'"
                      >
                        Pagina {{ paragraph.page }}
                      </font>
                      -
                      <font
                        :class="paragraph.css.cols == undefined ? 'red' : ''"
                      >
                        Coluna {{ paragraph.css.cols }}
                      </font>
                    </div>
                    <!-- </a-collapse-panel>
                                        </a-collapse>-->
                  </a-col>
                  <a-col class="a-center" :span="2">
                    <a-button
                      type="danger"
                      size="small"
                      shape="circle"
                      icon="delete"
                      class="mb-10"
                      ghost
                      @click="deleteParagraph(index)"
                    >
                    </a-button>

                    <a-divider />

                    <a-button
                      class="mb-10"
                      shape="circle"
                      size="large"
                      style="font-size: 10px"
                      @click="openParagraphCSS(paragraph, index)"
                    >
                      CSS
                    </a-button>
                    <a-divider />
                    <a-button
                      class="mb-10"
                      style="cursor: grab"
                      shape="circle"
                      size="large"
                      icon="menu"
                    >
                    </a-button>
                  </a-col>
                </a-row>
              </draggable>
            </a-col>
          </a-row>

          <a-row class="mt-20" style="margin-bottom: 300px">
            <a-col :span="22">
              <center>
                <a-button
                  @click="addParagraph(document_paragraph.length)"
                  type="primary"
                  ghost
                >
                  Adicionar parágrafo
                </a-button>
              </center>
              <!-- <div style="height: 300px; overflow: auto">
                                <prea>{{ document_paragraph }}</prea>
                            </div> -->
            </a-col>
          </a-row>
        </a-col>
        <a-col v-if="pageLoaded" class="col-1" :span="18">
          <a-row :span="24" :offset="0">
            <a-col
              style="
                background: #fff;
                padding: 20px;
                border-radius: 4px;
                border: 1px solid #d9d9d9;
              "
              :span="22"
            >
              <a-skeleton active />
              <a-skeleton active />
              <a-skeleton active />
              <a-skeleton active />
              <a-skeleton active />
              <a-skeleton active />
            </a-col>
          </a-row>
        </a-col>

        <a-col v-show="!pageLoaded" class="col-2 sidebar" :span="6">
          <a-row :gutter="16">
            <a-affix :target="() => this.$refs.container" :offset-top="20">
              <div class="box-update" style="margin: 0 8px 20px; padding: 20px">
                <a-row :gutter="20">
                  <a-col :span="24">
                    <h2 class="sidebar-title">Dados do documento</h2>
                  </a-col>
                  <a-col :span="24">
                    <a-form-item class="travel-input">
                      <label class="filled">Nome do modelo</label>
                      <a-input
                        class="input-title"
                        size="default"
                        v-decorator="[
                          'name',
                          {
                            initialValue: document.name,
                            rules: [
                              {
                                required: true,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]"
                        placeholder="Nome do modelo"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>

                  <a-col :span="24">
                    <a-checkbox
                      class="mb-20"
                      v-model="isGeneral"
                      style="
                        font-size: 12px;
                        font-weight: 500;
                        position: relative;
                      "
                      @change="onChangeIsGeneral"
                    >
                      Marque se este documento é para todas empresas
                    </a-checkbox>

                    <a-form-item style="display: none">
                      <a-input
                        v-decorator="[
                          'is_general',
                          {
                            initialValue: isGeneral,
                          },
                        ]"
                        :value="isGeneral"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col v-show="!isGeneral" :span="12">
                    <a-form-item class="travel-input-outer">
                      <label
                        :class="
                          form.getFieldValue(`company_id`) ? 'filled' : ''
                        "
                      >
                        Empresa
                      </label>

                      <a-select
                        class="travel-input"
                        placeholder="Selecione uma empresa"
                        optionFilterProp="txt"
                        @change="getCompanyBranchesOnChange"
                        v-decorator="[
                          `company_id`,
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]"
                        show-search
                        style="width: 100%"
                      >
                        <a-select-option
                          v-for="(item, index) of companiesList"
                          :key="index"
                          :value="item.id"
                          :txt="item.trading_name"
                        >
                          {{ item.id }} -
                          {{ item.trading_name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>

                  <a-col v-show="!isGeneral" :span="12">
                    <a-form-item class="travel-input-outer">
                      <label
                        :class="
                          form.getFieldValue(`company_branch_id`)
                            ? 'filled'
                            : ''
                        "
                      >
                        Filial
                      </label>

                      <a-select
                        class="travel-input"
                        placeholder="Selecione uma filial"
                        optionFilterProp="txt"
                        :disabled="companyBranchesList.length == 0"
                        v-decorator="[
                          `company_branch_id`,
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]"
                        show-search
                        style="width: 100%"
                      >
                        <a-select-option
                          v-for="(item, index) of companyBranchesList"
                          :key="index"
                          :value="item.id"
                          :txt="item.name"
                        >
                          {{ item.id }} -
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>

                  <a-col class="none" v-show="!isGeneral" :span="24">
                    <a-form-item style="display: inline-block">
                      <a-radio-group
                        v-decorator="[
                          'company',
                          {
                            initialValue: document.company,
                            rules: [
                              {
                                required: false,
                                message: 'Selecione a empresa.',
                              },
                            ],
                          },
                        ]"
                        :options="companies"
                    /></a-form-item>
                  </a-col>

                  <a-col :span="24">
                    <a-checkbox
                      class="mb-20"
                      v-model="activateDoc"
                      style="
                        font-size: 12px;
                        font-weight: 500;
                        position: relative;
                      "
                      @change="onChangeActivateDoc"
                    >
                      {{
                        activateDoc ? "Documento ativo" : "Documento desativado"
                      }}
                    </a-checkbox>

                    <a-form-item style="display: none">
                      <a-input
                        v-decorator="[
                          'status',
                          {
                            initialValue: activateDoc,
                          },
                        ]"
                        :value="activateDoc"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col :span="24">
                    <center>
                      <a-button
                        class="ml-20"
                        size="large"
                        type="primary"
                        html-type="submit"
                        :loading="loading"
                        :disabled="documentFirstLoading"
                      >
                        SALVAR ALTERAÇÕES
                      </a-button>
                    </center>
                  </a-col>
                </a-row>
              </div>

              <a-col :span="24">
                <a-collapse default-active-key="0" accordion forceRender>
                  <a-collapse-panel key="1" header="CONTRATANTE">
                    <a-divider orientation="left">
                      <a-icon type="table" /> Tabela completa
                    </a-divider>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TABELA CONTRATANTE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <br /><br />

                    <a-divider orientation="left">
                      <a-icon type="build" />
                      Fragmentos
                    </a-divider>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[NOME COMPLETO CONTRATANTE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[NASCIMENTO CONTRATANTE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CPF CONTRATANTE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CNPJ CONTRATANTE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[RG CONTRATANTE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[ORGÃO EMISSOR CONTRATANTE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CEP CONTRATANTE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[ENDEREÇO CONTRATANTE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[N° CASA CONTRATANTE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[BAIRRO CONTRATANTE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[COMPLEMENTO CONTRATANTE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[ESTADO CONTRATANTE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CIDADE CONTRATANTE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TELEFONE CONTRATANTE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CELULAR CONTRATANTE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[E-MAIL CONTRATANTE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[DATA DE HOJE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>
                  </a-collapse-panel>
                  <a-collapse-panel key="2" header="HOTEL" :disabled="false">
                    <a-divider orientation="left">
                      <a-icon type="table" />
                      Tabela completa
                    </a-divider>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TABELA HOTEIS] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <br /><br />
                    <a-divider orientation="left">
                      <a-icon type="build" />
                      Fragmentos
                    </a-divider>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[HOTEL 1 NOME] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[HOTEL 1 CHECKIN] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[HOTEL 1 CHECKOUT] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[HOTEL 1 DIARIAS] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[HOTEL 1 REGIME] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[HOTEL 1 TOTAL] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[HOTEL 1 ACOMODAÇÃO 1 NOME] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[HOTEL 1 ACOMODAÇÃO 1 TIPO] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>
                  </a-collapse-panel>
                  <a-collapse-panel key="3" header="AÉREO">
                    <a-divider orientation="left">
                      <a-icon type="table" />
                      Tabela completa
                    </a-divider>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TABELA AÉREO TRECHOS] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TABELA AÉREO VIAJANTES] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <br /><br />
                    <a-divider orientation="left">
                      <a-icon type="build" /> Cias | Fragmentos
                    </a-divider>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[AEREO 1 CIA 1 RAZAO SOCIAL] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[AEREO 1 CIA 1 NOME FANTASIA] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[AEREO 1 CIA 1 CNPJ] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <br /><br />
                    <a-divider orientation="left">
                      <a-icon type="build" />
                      Trechos | Fragmentos
                    </a-divider>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[AEREO 1 TOTAL] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[AEREO 1 TOTAL EXTENSO] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[AEREO 1 TRECHO 1 ORIGEM] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[AEREO 1 TRECHO 1 DESTINO] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[AEREO 1 TRECHO 1 DATA HORA EMBARQUE]
                        <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[AEREO 1 TRECHO 1 DATA HORA DESEMBARQUE]
                        <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[AEREO 1 TRECHO 1 DURAÇÃO] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[AEREO 1 TRECHO 1 CIA] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[AEREO 1 TRECHO 1 CLASSE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[AEREO 1 TRECHO 1 ID VOO] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[AEREO 1 TRECHO 1 PARADAS] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>
                  </a-collapse-panel>
                  <a-collapse-panel key="4" header="SERVIÇOS">
                    <a-divider orientation="left">
                      <a-icon type="table" />
                      Tabela completa
                    </a-divider>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TABELA SERVIÇOS] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <br /><br />
                    <a-divider orientation="left">
                      <a-icon type="build" />
                      Fragmentos
                    </a-divider>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[SERVIÇO 1 NOME] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[SERVIÇO 1 TOTAL] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>
                  </a-collapse-panel>
                  <a-collapse-panel key="10" header="VIAJANTES">
                    <a-divider orientation="left">
                      <a-icon type="table" />
                      Tabela completa
                    </a-divider>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TABELA VIAJANTES] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TABELA VIAJANTES CPF] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TABELA VIAJANTES VALOR AÉREO] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TABELA VIAJANTES TERCEIROS BENEFICIÁRIOS]
                        <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <br /><br />
                    <a-divider orientation="left">
                      <a-icon type="build" />
                      Fragmentos
                    </a-divider>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[VIAJANTE 1 NOME] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[VIAJANTE 1 NASCIMENTO] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[VIAJANTE 1 CPF] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[VIAJANTE 1 VALOR] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>
                  </a-collapse-panel>
                  <a-collapse-panel key="5" header="PAGAMENTOS">
                    <a-divider orientation="left">
                      <a-icon type="table" />
                      Tabela completa
                    </a-divider>
                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TABELA DE PAGAMENTOS] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <br /><br />
                    <a-divider orientation="left">
                      <a-icon type="build" />
                      Fragmentos
                    </a-divider>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TRANSFERENCIA BANCO NOME] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TRANSFERENCIA BANCO NUMERO] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TRANSFERENCIA BANCO AGENCIA] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TRANSFERENCIA BANCO CONTA] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TRANSFERENCIA VALOR] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[BOLETO VALOR] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[BOLETO PARCELAS] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CARTA DE CRÉDITO ID VOYAGE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CARTA DE CRÉDITO VALOR] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <br /><br />
                    <a-divider orientation="left">
                      <a-icon type="credit-card" />
                      Cartão de Crédito
                    </a-divider>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CARTÃO DE CRÉDITO 1] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CARTÃO DE CRÉDITO 1 VALOR] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CARTÃO DE CRÉDITO 1 NOME IMPRESSO]
                        <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CARTÃO DE CRÉDITO 1 NUMEROS] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CARTÃO DE CRÉDITO 1 NUMEROS 1-4] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CARTÃO DE CRÉDITO 1 NUMEROS 5-8] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CARTÃO DE CRÉDITO 1 NUMEROS 9-12] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CARTÃO DE CRÉDITO 1 NUMEROS 13-16]
                        <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CARTÃO DE CRÉDITO 1 EXPIRAÇÂO] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CARTÃO DE CRÉDITO 1 CVV] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CARTÃO DE CRÉDITO 1 BANDEIRA] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CARTÃO DE CRÉDITO 1 PARCELAS] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CARTÃO DE CRÉDITO 1 PRIMEIRA PARCELA]
                        <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CARTÃO DE CRÉDITO 1 DEMAIS PARCELAS]
                        <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[CARTÃO DE CRÉDITO 1 TAXAS] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>
                  </a-collapse-panel>
                  <a-collapse-panel key="6" header="TERCEIROS">
                    <a-divider orientation="left">
                      <a-icon type="table" />
                      Tabela completa
                    </a-divider>
                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TABELA TERCEIRO 1] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>
                    <br /><br />
                    <a-divider orientation="left">
                      <a-icon type="build" />
                      Fragmentos
                    </a-divider>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TERCEIRO 1 NOME COMPLETO] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TERCEIRO 1 NASCIMENTO] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TERCEIRO 1 CPF] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TERCEIRO 1 RG] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TERCEIRO 1 ORGÃO EMISSOR] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TERCEIRO 1 CEP] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TERCEIRO 1 ENDEREÇO] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TERCEIRO 1 N° CASA] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TERCEIRO 1 BAIRRO] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TERCEIRO 1 COMPLEMENTO] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TERCEIRO 1 ESTADO] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TERCEIRO 1 CIDADE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TERCEIRO 1 TELEFONE] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TERCEIRO 1 CELULAR] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TERCEIRO 1 E-MAIL] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>

                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TERCEIRO 1 VALOR PAGO] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>
                  </a-collapse-panel>
                  <a-collapse-panel key="7" header="POLÍTICAS">
                    <a-divider orientation="left">
                      <a-icon type="table" />
                      Tabela completa
                    </a-divider>
                    <a-tooltip placement="top">
                      <template slot="title"> Clique para copiar </template>
                      <a-tag @click="copyToClipboard"
                        >[TABELA POLÍTICAS] <a-icon type="copy"
                      /></a-tag>
                    </a-tooltip>
                  </a-collapse-panel>
                  <a-collapse-panel
                    forceRender
                    key="8"
                    header="TESTAR CONTRATO"
                  >
                    <a-row :gutter="16">
                      <a-col class="gutter-row" :span="10">
                        <a-form-item class="mt-10 mb-0 fade-label">
                          <label
                            :class="
                              this.form.getFieldValue(`contract_id`)
                                ? 'filled'
                                : ''
                            "
                          >
                            ID do contrato
                          </label>
                          <a-input
                            class="travel-input"
                            placeholder="ID do contrato"
                            v-decorator="[`contract_id`]"
                          >
                            <a-icon slot="prefix" type="field-svg" />
                          </a-input>
                        </a-form-item>
                      </a-col>
                      <a-col class="gutter-row" :span="6">
                        <a-tooltip placement="top" title="Visualizar documento">
                          <a
                            class="cblue"
                            style="
                              font-size: 24px;
                              top: 10px;
                              position: relative;
                            "
                            @click="view(document.id)"
                          >
                            <a-icon type="eye-svg" />
                          </a>
                        </a-tooltip>
                      </a-col>
                    </a-row>
                  </a-collapse-panel>
                </a-collapse>
                <input
                  id="copy-to-clipboard"
                  :value="copyToClipboardVal"
                  style="
                    opacity: 0;
                    height: 0px;
                    padding: 0;
                    line-height: 0;
                    border: 0;
                  "
                />
              </a-col>
            </a-affix>
          </a-row>
        </a-col>
        <a-col
          v-if="pageLoaded"
          style="
            background: #fff;
            padding: 20px;
            border-radius: 4px;
            border: 1px solid #d9d9d9;
          "
          class="col-2 sidebar"
          :span="8"
        >
          <a-skeleton active />
          <a-skeleton active />
          <a-skeleton active />
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import draggable from "vuedraggable";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";

export default {
  name: "Documents",
  components: {
    VueEditor,
    draggable,
    VSwatches,
  },
  data() {
    return {
      activeCollapse: -1,
      pageLoaded: false,
      selectedDoc: undefined,
      top: 10,
      bottom: 10,
      drag: false,
      statusIsChecked: false,
      activateDoc: false,
      listDocs: [],
      isGeneral: false,
      form: this.$form.createForm(this),
      companies: [
        { label: "Viajar Resorts", value: "Viajar Resorts" },
        { label: "Voe Simples", value: "Voe Simples" },
      ],
      companiesList: [],
      companyBranchesList: [],
      copyToClipboardVal: "",
      document_paragraph: [],
      document: {},
      loading: false,
      documentFirstLoading: true,
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"],
        [
          "align",
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          "link",
          "blockquote",
          {
            color: [],
          },
          {
            background: [],
          },
        ],
        ["clean"],
      ],
      swatches: ["#DDDDDD", "#222222", ""],
    };
  },
  beforeMount() {
    let body = document.body;
    body.classList.add("document-page");
    this.pageLoaded = true;

    this.$http.get("/document/read").then(({ data }) => {
      this.listDocs = data.data;
    });

    this.$http
      .post("/document/read_one", {
        id: this.$route.params.id,
      })
      .then(({ data }) => {
        this.documentFirstLoading = false;
        this.document = data;
        document.title = `${data.id} - ${data.name}`;

        this.getCompanyBranches(data.company_id);

        setTimeout(() => {
          this.form.setFieldsValue({
            title: data.title,
            subtitle: data.subtitle,
            contract_id: data.contract_id,
            company_branch_id: data.company_branch_id,
            company_id: data.company_id,
          });
        }, 100);

        this.activateDoc = data.status == "1" ? true : false;
        this.isGeneral = data.is_general == "1" ? true : false;

        this.statusIsChecked = data.status == "1" ? true : false;

        let paragraphs = decodeURIComponent(
          escape(window.atob(data.structure))
        );

        if (data.structure) {
          this.document_paragraph = JSON.parse(paragraphs);

          setTimeout(() => {
            JSON.parse(paragraphs).forEach((p) => {
              this.form.setFieldsValue({
                [`document_paragraph_${p.id}_page`]: p.page ? `${p.page}` : "1",
                [`document_paragraph_${p.id}_cols`]: p.css.cols
                  ? `${p.css.cols}`
                  : "1",
                [`document_paragraph_${p.id}_border_color`]: p.css.boderColor
                  ? p.css.boderColor
                  : "",
                [`document_paragraph_${p.id}_border_top`]: p.css.borderWidth.top
                  ? p.css.borderWidth.top
                  : 0,
                [`document_paragraph_${p.id}_border_right`]: p.css.borderWidth
                  .right
                  ? p.css.borderWidth.right
                  : 0,
                [`document_paragraph_${p.id}_border_bottom`]: p.css.borderWidth
                  .bottom
                  ? p.css.borderWidth.bottom
                  : 0,
                [`document_paragraph_${p.id}_border_left`]: p.css.borderWidth
                  .left
                  ? p.css.borderWidth.left
                  : 0,
                [`document_paragraph_${p.id}_cols`]: p.css.cols
                  ? `${p.css.cols}`
                  : 1,
                [`document_paragraph_${p.id}_padding_top`]: p.css.padding.top
                  ? p.css.padding.top
                  : "",
                [`document_paragraph_${p.id}_padding_right`]: p.css.padding
                  .right
                  ? p.css.padding.right
                  : "",
                [`document_paragraph_${p.id}_padding_bottom`]: p.css.padding
                  .bottom
                  ? p.css.padding.bottom
                  : "",
                [`document_paragraph_${p.id}_padding_left`]: p.css.padding.left
                  ? p.css.padding.left
                  : "",
                [`document_paragraph_${p.id}_margin_top`]: p.css.margin.top
                  ? p.css.margin.top
                  : "",
                [`document_paragraph_${p.id}_margin_right`]: p.css.margin.right
                  ? p.css.margin.right
                  : "",
                [`document_paragraph_${p.id}_margin_bottom`]: p.css.margin
                  .bottom
                  ? p.css.margin.bottom
                  : "",
                [`document_paragraph_${p.id}_margin_left`]: p.css.margin.left
                  ? p.css.margin.left
                  : "",
                [`document_paragraph_${p.id}_font_size`]: p.css.font.size
                  ? p.css.font.size
                  : "",
              });
            });
          }, 1000);
        } else {
          this.document_paragraph = [];
        }

        this.pageLoaded = false;
      })

      .finally(() => {
        this.loading = false;
      });

    this.$http
      .get(`/company/list?page=1&per_page=100`)
      .then(({ data }) => {
        this.companiesList = data.data;
      })
      .catch(({ response }) => {
        response;
      });
  },
  beforeDestroy() {
    let body = document.body;
    body.classList.remove("document-page");
  },
  methods: {
    goToSelectedDoc() {
      this.$router.push(`${this.selectedDoc}`);
      this.$router.go(this.$router.currentRoute);
    },
    getCompanyBranchesOnChange(id) {
      this.form.setFieldsValue({
        [`company_branch_id`]: undefined,
      });

      this.companyBranchesList = [];
      this.getCompanyBranches(id);
    },
    getCompanyBranches(companyId) {
      this.$http
        .get(
          `/company-branch/list?page=1&per_page=100&status=Ativo&company_id=${companyId}`
        )
        .then(({ data }) => {
          data;
          this.companyBranchesList = data.data;
        })
        .catch(({ response }) => {
          response;
        });
    },
    openParagraphCSS(p, index) {
      if (this.activeCollapse == index) {
        this.activeCollapse = -1;
      } else {
        this.activeCollapse = index;
      }

      setTimeout(() => {
        this.form.setFieldsValue({
          [`document_paragraph_${p.id}_page`]: p.page ? `${p.page}` : "1",
          [`document_paragraph_${p.id}_cols`]: p.css.cols
            ? `${p.css.cols}`
            : "1",
          [`document_paragraph_${p.id}_border_color`]: p.css.boderColor
            ? p.css.boderColor
            : "",
          [`document_paragraph_${p.id}_border_top`]: p.css.borderWidth.top
            ? p.css.borderWidth.top
            : 0,
          [`document_paragraph_${p.id}_border_right`]: p.css.borderWidth.right
            ? p.css.borderWidth.right
            : 0,
          [`document_paragraph_${p.id}_border_bottom`]: p.css.borderWidth.bottom
            ? p.css.borderWidth.bottom
            : 0,
          [`document_paragraph_${p.id}_border_left`]: p.css.borderWidth.left
            ? p.css.borderWidth.left
            : 0,
          [`document_paragraph_${p.id}_cols`]: p.css.cols ? `${p.css.cols}` : 1,
          [`document_paragraph_${p.id}_padding_top`]: p.css.padding.top
            ? p.css.padding.top
            : "",
          [`document_paragraph_${p.id}_padding_right`]: p.css.padding.right
            ? p.css.padding.right
            : "",
          [`document_paragraph_${p.id}_padding_bottom`]: p.css.padding.bottom
            ? p.css.padding.bottom
            : "",
          [`document_paragraph_${p.id}_padding_left`]: p.css.padding.left
            ? p.css.padding.left
            : "",
          [`document_paragraph_${p.id}_margin_top`]: p.css.margin.top
            ? p.css.margin.top
            : "",
          [`document_paragraph_${p.id}_margin_right`]: p.css.margin.right
            ? p.css.margin.right
            : "",
          [`document_paragraph_${p.id}_margin_bottom`]: p.css.margin.bottom
            ? p.css.margin.bottom
            : "",
          [`document_paragraph_${p.id}_margin_left`]: p.css.margin.left
            ? p.css.margin.left
            : "",
          [`document_paragraph_${p.id}_font_size`]: p.css.font.size
            ? p.css.font.size
            : "",
        });
      }, 100);
    },
    openCollapse(p) {
      let flag = false;

      if (p != undefined) {
        if (p.openCSS == 1) flag = true;
      }
      return flag;
    },
    onChangeActivateDoc(val) {
      val;
    },
    onChangeIsGeneral(val) {
      val;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.updateDocument(values);
        }
      });
    },
    updateParagraph(index, id, p) {
      for (var i in this.document_paragraph) {
        if (i == index) {
          this.document_paragraph[i].p = p;
          setTimeout(() => {
            this.form.setFieldsValue({
              [`document_paragraph_${id}`]: p,
            });
          }, 20);
        }
      }
    },
    updateParagraphExtraCongifs(index, id, paragraph) {
      setTimeout(() => {
        for (var i in this.document_paragraph) {
          if (i == index) {
            setTimeout(() => {
              this.document_paragraph[i].page = `${
                this.form.getFieldValue(`document_paragraph_${id}_page`) !=
                undefined
                  ? this.form.getFieldValue(`document_paragraph_${id}_page`)
                  : this.document_paragraph[index].page
              }`;
            }, 200);

            this.document_paragraph[i].css = {
              cols: `${
                this.form.getFieldValue(`document_paragraph_${id}_cols`) !=
                undefined
                  ? this.form.getFieldValue(`document_paragraph_${id}_cols`)
                  : this.document_paragraph[i].css.cols
              }`,
              borderWidth: {
                top: this.form.getFieldValue(
                  `document_paragraph_${id}_border_top`
                )
                  ? this.form.getFieldValue(
                      `document_paragraph_${id}_border_top`
                    )
                  : paragraph.css.borderWidth.top,
                left: this.form.getFieldValue(
                  `document_paragraph_${id}_border_left`
                )
                  ? this.form.getFieldValue(
                      `document_paragraph_${id}_border_left`
                    )
                  : paragraph.css.borderWidth.left,
                right: this.form.getFieldValue(
                  `document_paragraph_${id}_border_right`
                )
                  ? this.form.getFieldValue(
                      `document_paragraph_${id}_border_right`
                    )
                  : paragraph.css.borderWidth.right,
                bottom: this.form.getFieldValue(
                  `document_paragraph_${id}_border_bottom`
                )
                  ? this.form.getFieldValue(
                      `document_paragraph_${id}_border_bottom`
                    )
                  : paragraph.css.borderWidth.bottom,
              },
              boderColor: this.form.getFieldValue(
                `document_paragraph_${id}_border_color`
              )
                ? this.form.getFieldValue(
                    `document_paragraph_${id}_border_color`
                  )
                : paragraph.css.boderColor,
              padding: {
                top: this.form.getFieldValue(
                  `document_paragraph_${id}_padding_top`
                )
                  ? this.form.getFieldValue(
                      `document_paragraph_${id}_padding_top`
                    )
                  : paragraph.css.padding.top,
                right: this.form.getFieldValue(
                  `document_paragraph_${id}_padding_right`
                )
                  ? this.form.getFieldValue(
                      `document_paragraph_${id}_padding_right`
                    )
                  : paragraph.css.padding.right,
                bottom: this.form.getFieldValue(
                  `document_paragraph_${id}_padding_bottom`
                )
                  ? this.form.getFieldValue(
                      `document_paragraph_${id}_padding_bottom`
                    )
                  : paragraph.css.padding.bottom,
                left: this.form.getFieldValue(
                  `document_paragraph_${id}_padding_left`
                )
                  ? this.form.getFieldValue(
                      `document_paragraph_${id}_padding_left`
                    )
                  : paragraph.css.padding.left,
              },
              margin: {
                top: this.form.getFieldValue(
                  `document_paragraph_${id}_margin_top`
                )
                  ? this.form.getFieldValue(
                      `document_paragraph_${id}_margin_top`
                    )
                  : paragraph.css.margin.top,
                right: this.form.getFieldValue(
                  `document_paragraph_${id}_margin_right`
                )
                  ? this.form.getFieldValue(
                      `document_paragraph_${id}_margin_right`
                    )
                  : paragraph.css.margin.right,
                bottom: this.form.getFieldValue(
                  `document_paragraph_${id}_margin_bottom`
                )
                  ? this.form.getFieldValue(
                      `document_paragraph_${id}_margin_bottom`
                    )
                  : paragraph.css.margin.bottom,
                left: this.form.getFieldValue(
                  `document_paragraph_${id}_margin_left`
                )
                  ? this.form.getFieldValue(
                      `document_paragraph_${id}_margin_left`
                    )
                  : paragraph.css.margin.left,
              },
              font: {
                size: this.form.getFieldValue(
                  `document_paragraph_${id}_font_size`
                )
                  ? this.form.getFieldValue(
                      `document_paragraph_${id}_font_size`
                    )
                  : paragraph.css.font.size,
              },
            };
          }
        }
      }, 100);
    },
    copyToClipboard(e) {
      let copyText = "";
      this.copyToClipboardVal = e.target.innerText;
      setTimeout(() => {
        copyText = document.getElementById("copy-to-clipboard");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        this.$message.info("Copiado!");
      }, 20);
    },
    view(id) {
      // setTimeout(() => {
      //     window.open(`/documents/view/${id}`, "_blank");
      // }, 500);

      this.$router.push(`/documents/view/${id}`);
    },
    addParagraph(index) {
      this.activeCollapse = index;

      this.document_paragraph.push({
        p: "",
        id: index + 1,
        page:
          this.document_paragraph[index - 1] != undefined
            ? this.document_paragraph[index - 1].page
            : 1,
        css: {
          cols:
            this.document_paragraph[index - 1] != undefined
              ? this.document_paragraph[index - 1].css.cols
              : 1, // "1",
          margin: {
            top: "inherit",
            left: "inherit",
            right: "inherit",
            bottom: "inherit",
          },
          borderWidth: {
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
          },
          boderColor: "",
          padding: {
            top: "inherit",
            left: "inherit",
            right: "inherit",
            bottom: "inherit",
          },
          font: {
            size: "inherit",
          },
        },
      });

      setTimeout(() => {
        this.form.setFieldsValue({
          [`document_paragraph_${index + 1}_page`]:
            this.document_paragraph[index - 1] != undefined
              ? this.document_paragraph[index - 1].page
              : 1,
          [`document_paragraph_${index + 1}_cols`]:
            this.document_paragraph[index - 1] != undefined
              ? this.document_paragraph[index - 1].css.cols
              : 1,
        });

        this.updateParagraphExtraCongifs(index, index + 1);
      }, 50);
    },
    deleteParagraph(index) {
      this.activeCollapse = -1;
      for (var i in this.document_paragraph) {
        if (i == index) {
          this.document_paragraph.splice(i, 1);
          break;
        }
      }
    },
    changeStatus(e) {
      this.statusIsChecked = e;
    },
    startDrag() {
      this.drag = true;
      this.activeCollapse = -1;
    },
    endDrag() {
      this.drag = false;
      this.activeCollapse = -1;
    },
    updateDocument(documentData) {
      this.loading = true;
      documentData.id = this.document.id;
      documentData.user = this.document.user;

      let paragraphs = this.document_paragraph;
      paragraphs = JSON.stringify(paragraphs);

      documentData.structure = btoa(unescape(encodeURIComponent(paragraphs)));

      documentData.html = this.document_paragraph;

      documentData.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.$http
        .post("/document/update", documentData)
        .then(({ data }) => {
          this.loading = false;
          this.$message.success(data.message);
        })
        .catch(({ response }) => {
          this.loading = false;
          this.$message.error(response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.doc-title
  font-size: 16px !important
  max-width: 900px
  display: inline-block
</style>

<style lang="sass">
.travel-collapse
  background: #FFF
  padding: 20px
  border: 1px solid #d9d9d9
  border-radius: 0 0 6px 6px
  border-width: 0px 1px 1px

.document-page .ant-affix
  overflow: auto
  height: 600px !important

.document-page
  .ant-layout
    overflow: hidden
  .ant-collapse-content-box
    overflow: auto
    max-height: 300px
    overflow-x: hidden
  .ant-collapse-header
    font-size: 12px
    padding: 6px 6px 6px 38px !important
    color: #292828 !important
    font-weight: 600 !important
  .paragraphs.is-dragging
    .paragraph
          opacity: 0.5
          margin-bottom: 20px
  .paragraphs
      .paragraph
          margin-bottom: 20px
          .ant-form-explain
              display: none
          .ant-row.ant-form-item
              margin-bottom: 0 !important
          .ant-form-item-control.has-error
              .ql-editor.ql-blank::before
                  color: red
              .quillWrapper
                  border-color: red
                  border-bottom: 1px solid red
          .ql-snow.ql-toolbar
              border-radius: 6px 6px 0 0 !important
          .ant-collapse
              border-radius: 0 0 6px 6px !important
          .ant-collapse-header
              border-radius: 0 0 6px 6px !important
          .info.ant-row
              font-weight: 500
              font-size: 12px
              color: #000
              margin-bottom: 7px
          .label
              font-weight: 600
              font-size: 12px
              position: relative
              top: 11px
              color: #4c388d


.document-page .ant-layout-content
    padding: 0 !important

.document-page .sidebar-title
    font-size: 14px
    padding: 0 0 12px
    border-bottom: 1px solid #ddd
    text-transform: uppercase
    margin-bottom: 25px
    color: #be4178 !important
    font-weight: 600


.document-page .documents.edit
    overflow: auto !important
    height: 93vh
    padding: 24px
    overflow-x: hidden


.document-page .ant-divider
    margin: 0 0 10px !important


.document-page .ant-divider-inner-text
    font-size: 12px

.document-page main.ant-layout-content,
.document-page .dashboard .ant-layout
    background: #f6f6f6 !important


.document-page .ant-input
    border-radius: 4px


.documents.edit .ql-editor
    min-height: 80px !important
    max-height: 300px
    min-height: 160px !important
    max-height: 40px


.documents.edit .quillWrapper
    border: 1px solid #d9d9d9
    border-radius: 6px 6px 0 0

.documents.edit .ql-snow .ql-picker-options .ql-picker-item
    line-height: 1


.documents.edit .ql-snow .ql-picker-label::before
    position: relative
    top: -9px !important

.documents.edit .ant-tag
    margin: 0 5px 5px 0 !important
    padding: 2px 5px !important
    border-radius: 0 !important
    color: #666 !important
    font-weight: 500
    border-color: #eee !important
    cursor: pointer

.documents.edit .ant-card-body
    padding: 10px !important

.documents.edit .vue-swatches__wrapper
    padding: 0 !important

.documents.edit .vue-swatches__fallback__input
    height: 27px

.documents.edit .vue-swatches__trigger
    height: 32px !important
    width: 32px !important
    margin-top: 0px
    border-radius: 4px !important

.documents.edit .vue-swatches__fallback__button
    height: 27px
    line-height: 1
    margin-left: 10px

.documents.edit .vue-swatches__fallback__wrapper
    padding: 5px 0px 0 !important
    margin: 0 !important
    line-height: 0

.documents.edit .vue-swatches__swatch
    height: 32px !important
    width: 32px !important
    border-radius: 4px !important

.documents.edit .vue-swatches__wrapper
    display: block !important
    line-height: 0
    margin-bottom: -13px
</style>
